(function (angular, _) {
    var MyHippoCommonsDirectives = angular.module('MyHippoCommons.Directives', ['ui.bootstrap']);
    angular.module('MyHippoFormly.Directives', []);

    MyHippoCommonsDirectives.directive('hpConvertToNumber', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return parseFloat(val);
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    });

    MyHippoCommonsDirectives.directive('hpConvertToBoolean', function () {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                ngModel.$parsers.push(function (val) {
                    return val === 'true';
                });
                ngModel.$formatters.push(function (val) {
                    return '' + val;
                });
            }
        };
    });

    MyHippoCommonsDirectives.directive('mhCharCounter', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs) {
                var counterElem = angular.element(`<span class='mh-char-counter'></span>`);
                element.after(counterElem);
                var updateCounterText = function () {
                    // console.log('updateCounterText', maxlength, element.val(), element.val().length);
                    var elemValue = element.val();
                    var remainingChars = maxlength - (elemValue ? elemValue.length : 0);
                    counterElem.text(remainingChars + ' left');
                };

                var maxlength;
                attrs.$observe('maxlength', function (val) {
                    maxlength = val;
                    if (maxlength) updateCounterText();
                });
                scope.$watch(attrs.ngModel, updateCounterText);

                element.bind('focus', function () {
                    if (maxlength) counterElem.addClass('visible');
                });
                element.bind('blur', function () {
                    counterElem.removeClass('visible');
                });
            }
        };
    });

    MyHippoCommonsDirectives.factory('CommonAPIService', function ($http, $q) {
        var CommonAPIService = {
            suggestAddress: function (prefix, filters) {
                var params = _.cloneDeep(filters);
                params['auth-id'] = '22806898265935130';
                params['prefix'] = prefix || '';
                return $http({
                    url: 'https://us-autocomplete.api.smartystreets.com/suggest',
                    method: 'GET',
                    params: params,
                    cache: false
                }).then(function (response) {
                    return response.data.suggestions;
                });
            },
            validateAddress: function (params) {
                return $http({
                    url: 'https://us-street.api.smartystreets.com/street-address',
                    method: 'GET',
                    params: {
                        'auth-id': '22806898265935130',
                        'license': 'us-rooftop-geocoding-enterprise-cloud',
                        'match': 'enhanced',
                        'street': params.street,
                        'city': params.city,
                        'state': params.state
                    },
                    cache: false
                }).then(function (response) {
                    return response.data;
                });
            }
        };
        return CommonAPIService;
    });

    MyHippoCommonsDirectives.filter('isEmpty', function () {
        return function (object) {
            return angular.equals({}, object);
        };
    });
})(window.angular, window._);
